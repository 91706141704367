var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "px-4 overflow-y-auto",
      style: {
        height: _vm.$vuetify.breakpoint.lgAndUp
          ? _vm.docsTabHeight + "px"
          : "auto"
      }
    },
    [
      _c("FilesFilterDialog", {
        attrs: {
          showFilesFilterDialog: _vm.showFilesFilterDialog,
          savedFilterChoices: _vm.filterChoices
        },
        on: {
          "files-filter-dialog-close": function($event) {
            _vm.showFilesFilterDialog = false
          },
          "update-filter": _vm.onUpdateFilter
        }
      }),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center",
              class: { background: _vm.inRightPane },
              staticStyle: { overflow: "hidden" }
            },
            [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
            1
          )
        : [
            _vm.foldersAtLevel.length > 0 || _vm.files.length > 0
              ? [
                  _c(
                    "section",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: {
                            text: "",
                            color: "#3F51B5",
                            id: "docsFilterBtn"
                          },
                          on: {
                            click: function($event) {
                              _vm.showFilesFilterDialog = true
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiFilter))]),
                          _vm._v(" Filter ")
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-wrap gap" },
                        _vm._l(_vm.filterChoices, function(f) {
                          return _c(
                            "v-chip",
                            {
                              key: f.selectedField,
                              attrs: { close: "" },
                              on: {
                                "click:close": function($event) {
                                  return _vm.removeFilter(f.selectedField)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("filesFieldMap")(f.selectedField)
                                  ) +
                                  " "
                              ),
                              _c("MatchIcon", {
                                staticClass: "mx-2",
                                attrs: { value: f.selectedMatchChoice }
                              }),
                              _vm._v(" " + _vm._s(f.selectedMatchValue) + " ")
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("DocsBreadcrumb", {
                    staticClass: "py-3",
                    attrs: { folderPath: _vm.currentFolder },
                    on: { "navigate-folder": _vm.onNavigateToFolder }
                  }),
                  _c("v-divider", { staticClass: "my-2" }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end px-5" },
                    [
                      _c(
                        "v-menu",
                        {
                          staticClass: "px-0 mx-0",
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              icon: "",
                                              id: "docsMoreOptions"
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(_vm.mdiDotsVertical))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3932244478
                          )
                        },
                        [
                          _c(
                            "v-list",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { id: "docsMoreOptionsItems" }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "pa-3 mx-0 d-block",
                                  attrs: { id: "menu" }
                                },
                                [
                                  _c("div", { staticClass: "caption bold" }, [
                                    _vm._v("Sort By")
                                  ]),
                                  _c("ul", [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.NEWEST_FIRST
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.NEWEST_FIRST
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.NEWEST_FIRST
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.NEWEST_FIRST
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Newest First ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.OLDEST_FIRST
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.OLDEST_FIRST
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.OLDEST_FIRST
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.OLDEST_FIRST
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Oldest First ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.NAME_ASC
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.NAME_ASC
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.NAME_ASC
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.NAME_ASC
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Name A - Z ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.NAME_DESC
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.NAME_DESC
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.NAME_DESC
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.NAME_DESC
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Name Z - A ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.SMALLEST_FIRST
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.SMALLEST_FIRST
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.SMALLEST_FIRST
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES
                                                .SMALLEST_FIRST
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Smallest First ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.LARGEST_FIRST
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.LARGEST_FIRST
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.LARGEST_FIRST
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.LARGEST_FIRST
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Largest First ")]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.foldersAtLevel, function(f) {
                    return _c(
                      "div",
                      {
                        key: f.folder,
                        staticClass:
                          "d-flex gap align-center py-1 cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.onFolderClick(f.folder)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                              _vm._v(" " + _vm._s(_vm.mdiFolder) + " ")
                            ])
                          ],
                          1
                        ),
                        _c("div", { style: { color: "#00A9F4" } }, [
                          _vm._v(
                            " " + _vm._s(f.folder.split("/").slice(-1)[0]) + " "
                          )
                        ])
                      ]
                    )
                  }),
                  _vm._l(_vm.files, function(f) {
                    return _c(
                      "div",
                      {
                        key: f.file_id,
                        staticClass:
                          "d-flex gap align-center py-1 cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.onViewButtonClick(f)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex align-center text-truncate",
                            staticStyle: { width: "80%" }
                          },
                          [
                            f.s3_file_path.endsWith("png") ||
                            f.s3_file_path.endsWith("jpg") ||
                            f.s3_file_path.endsWith("jpeg")
                              ? _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-right": "16px"
                                  },
                                  attrs: { src: f.s3_file_path }
                                })
                              : _c("v-icon", { staticClass: "mr-3" }, [
                                  _vm._v(_vm._s(_vm.mdiFile) + " ")
                                ]),
                            _c("section", { staticClass: "text-truncate" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "text-truncate py-0 my-0",
                                  staticStyle: { "max-width": "100%" }
                                },
                                [_vm._v(" " + _vm._s(f.name) + " ")]
                              ),
                              _c("div", { staticClass: "caption py-0 my-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("formatDate")(f.created_on)) +
                                    " - " +
                                    _vm._s((f.file_size_kb / 1000).toFixed(2)) +
                                    "M "
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  })
                ]
              : _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center" },
                  [_vm._v(" No Documents Added ")]
                )
          ],
      _vm.showPdfViewerDialog
        ? _c("PublicSitePdfViewerDialog", {
            attrs: {
              showPdfViewerDialog: _vm.showPdfViewerDialog,
              selectedFile: _vm.selectedFile
            },
            on: {
              "view-pdf-dialog-close": function($event) {
                _vm.showPdfViewerDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showImageViewerDialog
        ? _c("ImageViewerDialog", {
            attrs: {
              showImageViewerDialog: _vm.showImageViewerDialog,
              selectedFile: _vm.selectedFile
            },
            on: {
              "view-image-dialog-close": function($event) {
                _vm.showImageViewerDialog = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }