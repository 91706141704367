var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "v-expansion-panels",
        {
          staticClass: "py-3 mx-0 px-0",
          attrs: { flat: "", multiple: "" },
          model: {
            value: _vm.openPanels,
            callback: function($$v) {
              _vm.openPanels = $$v
            },
            expression: "openPanels"
          }
        },
        [
          _c(
            "v-expansion-panel",
            { attrs: { value: "0" } },
            [
              _c(
                "v-expansion-panel-header",
                { staticClass: "text-uppercase nav-border-top" },
                [_vm._v(" UtiliSync Fields ")]
              ),
              _c("v-expansion-panel-content", { staticClass: "py-0" }, [
                _vm.gisDataFields.length > 0
                  ? _c(
                      "section",
                      { staticClass: "ma-0" },
                      _vm._l(_vm.gisDataFields, function(f) {
                        return _c(
                          "div",
                          {
                            key: f.gis_data_value_id,
                            staticClass: "py-3 ma-0"
                          },
                          [
                            _c("div", [
                              _c("div", { staticClass: "caption" }, [
                                _vm._v(" " + _vm._s(f.name) + " ")
                              ]),
                              _c("div", [_vm._v(" " + _vm._s(f.value) + " ")])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _c("section", { staticClass: "py-3" }, [
                      _c("div", { staticClass: "caption" }, [
                        _vm._v("No UtiliSync fields configured")
                      ])
                    ])
              ])
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            { attrs: { value: "1" } },
            [
              _c(
                "v-expansion-panel-header",
                { staticClass: "text-uppercase nav-border-top my-0 py-0" },
                [_vm._v(" GIS Fields ")]
              ),
              _vm.publicSite.token_type === "NONE"
                ? _c(
                    "v-expansion-panel-content",
                    { staticClass: "my-0 py-0" },
                    [
                      Object.keys(_vm.gisInfoAttributes).length > 0
                        ? _c(
                            "section",
                            { staticClass: "ma-0" },
                            _vm._l(_vm.gisInfoAttributes, function(value, key) {
                              return _c(
                                "div",
                                { key: key, staticClass: "py-3" },
                                [
                                  _c("div", { staticClass: "caption" }, [
                                    _vm._v(" " + _vm._s(key) + " ")
                                  ]),
                                  _c("div", [_vm._v(" " + _vm._s(value) + " ")])
                                ]
                              )
                            }),
                            0
                          )
                        : _c("section", { staticClass: "ma-0" }, [
                            _c("div", { staticClass: "caption" }, [
                              _vm._v("No GIS fields configured")
                            ])
                          ])
                    ]
                  )
                : _c("v-expansion-panel-content", [
                    _c("div", { staticClass: "caption" }, [
                      _vm._v("Data Not Available")
                    ])
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }