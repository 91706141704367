<template>
  <section>
    <v-expansion-panels
      flat
      multiple
      v-model="openPanels"
      class="py-3 mx-0 px-0"
    >
      <v-expansion-panel value="0">
        <v-expansion-panel-header class="text-uppercase nav-border-top">
          UtiliSync Fields
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-0">
          <div
            v-for="f of gisDataFields"
            :key="f.gis_data_value_id"
            class="py-3 ma-0 px-0"
          >
            <div>
              <div class="caption">
                {{ f.name }}
              </div>
              <div>
                {{ f.value }}
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
export default {
  name: "PublicSiteGisInfo",
  props: {
    gisDataFields: Array,
  },
  data() {
    return { openPanels: [0] };
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}
</style>
