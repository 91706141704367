<template>
  <section
    class="px-4 overflow-y-auto"
    :style="{
      height: $vuetify.breakpoint.lgAndUp ? `${docsTabHeight}px` : 'auto',
    }"
  >
    <FilesFilterDialog
      :showFilesFilterDialog="showFilesFilterDialog"
      :savedFilterChoices="filterChoices"
      @files-filter-dialog-close="showFilesFilterDialog = false"
      @update-filter="onUpdateFilter"
    />

    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="overflow: hidden"
      :class="{ background: inRightPane }"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <template v-else>
      <template v-if="foldersAtLevel.length > 0 || files.length > 0">
        <section class="d-flex align-center">
          <v-btn
            text
            color="#3F51B5"
            @click="showFilesFilterDialog = true"
            id="docsFilterBtn"
            class="pa-0 ma-0"
          >
            <v-icon> {{ mdiFilter }}</v-icon>
            Filter
          </v-btn>

          <div class="d-flex flex-wrap gap">
            <v-chip
              v-for="f of filterChoices"
              :key="f.selectedField"
              close
              @click:close="removeFilter(f.selectedField)"
            >
              {{ f.selectedField | filesFieldMap }}
              <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
              {{ f.selectedMatchValue }}
            </v-chip>
          </div>
        </section>

        <DocsBreadcrumb
          :folderPath="currentFolder"
          @navigate-folder="onNavigateToFolder"
          class="py-3"
        />

        <v-divider class="my-2"></v-divider>

        <div class="d-flex justify-end px-5">
          <v-menu offset-y class="px-0 mx-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" id="docsMoreOptions">
                <v-icon>{{ mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0 my-0" id="docsMoreOptionsItems">
              <v-list-item class="pa-3 mx-0 d-block" id="menu">
                <div class="caption bold">Sort By</div>

                <ul>
                  <li
                    class="py-2"
                    @mouseenter="
                      highlightedChoice = ORDER_BY_CHOICES.NEWEST_FIRST
                    "
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.NEWEST_FIRST)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.NEWEST_FIRST),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.NEWEST_FIRST
                      ),
                    }"
                  >
                    Newest First
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="
                      highlightedChoice = ORDER_BY_CHOICES.OLDEST_FIRST
                    "
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.OLDEST_FIRST)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.OLDEST_FIRST),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.OLDEST_FIRST
                      ),
                    }"
                  >
                    Oldest First
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="highlightedChoice = ORDER_BY_CHOICES.NAME_ASC"
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.NAME_ASC)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.NAME_ASC),
                      ...getLinkBackgroundColorStyle(ORDER_BY_CHOICES.NAME_ASC),
                    }"
                  >
                    Name A - Z
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="highlightedChoice = ORDER_BY_CHOICES.NAME_DESC"
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.NAME_DESC)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.NAME_DESC),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.NAME_DESC
                      ),
                    }"
                  >
                    Name Z - A
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="
                      highlightedChoice = ORDER_BY_CHOICES.SMALLEST_FIRST
                    "
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.SMALLEST_FIRST)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.SMALLEST_FIRST),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.SMALLEST_FIRST
                      ),
                    }"
                  >
                    Smallest First
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="
                      highlightedChoice = ORDER_BY_CHOICES.LARGEST_FIRST
                    "
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.LARGEST_FIRST)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.LARGEST_FIRST),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.LARGEST_FIRST
                      ),
                    }"
                  >
                    Largest First
                  </li>
                </ul>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div
          class="d-flex gap align-center py-1 cursor-pointer"
          v-for="f of foldersAtLevel"
          :key="f.folder"
          @click="onFolderClick(f.folder)"
        >
          <div>
            <v-icon color="#3F51B5">
              {{ mdiFolder }}
            </v-icon>
          </div>
          <div :style="{ color: '#00A9F4' }">
            {{ f.folder.split("/").slice(-1)[0] }}
          </div>
        </div>

        <div
          class="d-flex gap align-center py-1 cursor-pointer"
          v-for="f of files"
          :key="f.file_id"
          @click="onViewButtonClick(f)"
        >
          <div class="d-flex align-center text-truncate" style="width: 80%">
            <img
              :src="f.s3_file_path"
              v-if="
                f.s3_file_path.endsWith('png') ||
                f.s3_file_path.endsWith('jpg') ||
                f.s3_file_path.endsWith('jpeg')
              "
              style="width: 20px; margin-right: 16px"
            />
            <v-icon class="mr-3" v-else>{{ mdiFile }} </v-icon>
            <section class="text-truncate">
              <div class="text-truncate py-0 my-0" style="max-width: 100%">
                {{ f.name }}
              </div>
              <div class="caption py-0 my-0">
                {{ f.created_on | formatDate }} -
                {{ (f.file_size_kb / 1000).toFixed(2) }}M
              </div>
            </section>
          </div>
        </div>
      </template>
      <div v-else class="d-flex justify-center align-center">
        No Documents Added
      </div>
    </template>

    <PublicSitePdfViewerDialog
      v-if="showPdfViewerDialog"
      @view-pdf-dialog-close="showPdfViewerDialog = false"
      :showPdfViewerDialog="showPdfViewerDialog"
      :selectedFile="selectedFile"
    />

    <ImageViewerDialog
      v-if="showImageViewerDialog"
      @view-image-dialog-close="showImageViewerDialog = false"
      :showImageViewerDialog="showImageViewerDialog"
      :selectedFile="selectedFile"
    />
  </section>
</template>

<script>
import { axiosWithNoAuth } from "@/plugins/axios";
import DocsBreadcrumb from "@/components/mapView/docs-tab/DocsBreadcrumb.vue";
import { mdiFolder, mdiFile, mdiDotsVertical, mdiFilter } from "@mdi/js";
import PublicSitePdfViewerDialog from "@/components/public-site/public-site-docs-tab/PublicSitePdfViewerDialog";
import ImageViewerDialog from "@/components/mapView/docs-tab/ImageViewerDialog";
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";
import FilesFilterDialog from "@/components/mapView/docs-tab/FilesFilterDialog";

const APIURL = process.env.VUE_APP_API_URL;
const ORDER_BY_CHOICES = {
  NEWEST_FIRST: "newest-first",
  OLDEST_FIRST: "oldest-first",
  NAME_ASC: "name-asc",
  NAME_DESC: "name-desc",
  SMALLEST_FIRST: "smallest-first",
  LARGEST_FIRST: "largest-first",
};
const DOWNLOAD_CHOICES = {
  ALL_FILES: "all-files",
  FILTERED_FILES: "filtered-files",
};

export default {
  name: "PublicSiteDocsTab",
  components: {
    DocsBreadcrumb,
    PublicSitePdfViewerDialog,
    ImageViewerDialog,
    MatchIcon,
    FilesFilterDialog,
  },
  props: {
    publicSite: Object,
    gisDataPointId: String,
    inRightPane: Boolean,
  },
  data() {
    return {
      currentFolder: undefined,
      files: [],
      folders: [],
      mdiFolder,
      mdiFile,
      mdiDotsVertical,
      mdiFilter,
      level: 1,
      showPdfViewerDialog: false,
      showImageViewerDialog: false,
      selectedFile: {},
      filterChoices: [],
      ORDER_BY_CHOICES,
      DOWNLOAD_CHOICES,
      orderBy: ORDER_BY_CHOICES.NEWEST_FIRST,
      highlightedChoice: undefined,
      highlightedFileName: undefined,
      showFilesFilterDialog: false,
      docsTabHeight: window.innerHeight * 0.8 - 50,
      loading: true,
    };
  },
  computed: {
    allFolders() {
      const folders = this.folders
        ?.map((f) => {
          const splitPath = f.folder.split("/");
          const subfolders = Array(splitPath.length)
            .fill()
            .map((_, i) => splitPath.slice(0, i + 1).join("/"));
          return [...subfolders];
        })
        .flat()
        .filter((f) => Boolean(f) && !f.includes("undefined"));
      const foldersWithoutDups = [...new Set(folders)];
      return foldersWithoutDups.map((folderName) => {
        const folder = this.folders?.find((fs) =>
          fs.folder.includes(folderName)
        );
        return {
          folder: folderName,
          cover_image_url: folder?.cover_image_url,
        };
      });
    },
    foldersAtLevel() {
      return this.allFolders?.filter(
        (f) => f?.folder?.split("/")?.length === this.level
      );
    },
  },
  methods: {
    onResize() {
      this.docsTabHeight = window.innerHeight * 0.8 - 50;
    },
    async onUpdateFilter(filterChoices) {
      this.filterChoices = filterChoices;
      this.showFilesFilterDialog = false;
      await this.getFiles();
    },
    async orderFiles(orderBy) {
      this.orderBy = orderBy;
      await this.getFiles();
    },
    getFileBackgroundColorStyle(fileOrFolderId) {
      return {
        backgroundColor:
          this.highlightedFileName === fileOrFolderId ? "#fafafa" : undefined,
      };
    },
    getLinkColorStyle(orderByChoice) {
      return {
        color: this.orderBy === orderByChoice ? "rgb(33, 150, 243)" : undefined,
      };
    },
    getLinkBackgroundColorStyle(orderByChoice) {
      return {
        backgroundColor:
          this.highlightedChoice === orderByChoice ? "#fafafa" : undefined,
      };
    },
    async removeFilter(selectedField) {
      const index = this.filterChoices.find(
        (f) => f.selectedField === selectedField
      );
      this.filterChoices.splice(index, 1);
      await this.getFolders();
      await this.getFiles();
    },
    onViewButtonClick(file) {
      this.selectedFile = file;
      if (
        file.s3_file_path.endsWith("png") ||
        file.s3_file_path.endsWith("jpg") ||
        file.s3_file_path.endsWith("jpeg")
      ) {
        this.showImageViewerDialog = true;
      } else if (file.s3_file_path.endsWith("pdf")) {
        this.showPdfViewerDialog = true;
      }
    },
    async onNavigateToFolder(index) {
      if (typeof index === "undefined") {
        this.currentFolder = undefined;
        this.level = 1;
        await this.getFiles();
        return;
      }
      this.currentFolder = this.currentFolder
        ?.split("/")
        ?.slice(0, index + 1)
        ?.join("/");
      this.level = index + 2;
      await this.getFiles();
    },
    onFolderClick(folderName) {
      this.level++;
      this.currentFolder = folderName;
      this.getFiles();
    },
    async getFolders() {
      const { map_service_id: mapServiceId, feature_id: objectId } =
        this.publicSite;
      if (!mapServiceId || !objectId || !this.gisDataPointId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithNoAuth.get(
        `${APIURL}/public_site/map_services/${mapServiceId}/features/${this.gisDataPointId}/folders`,
        {
          params: {
            object_id: objectId,
          },
        }
      );
      this.folders = results;
    },
    async getFiles() {
      const { map_service_id: mapServiceId, feature_id: objectId } =
        this.publicSite;
      if (!mapServiceId || !objectId || !this.gisDataPointId) {
        return;
      }
      const { currentFolder, orderBy, filterChoices } = this;
      const [{ selectedMatchChoice, selectedMatchValue } = {}] = filterChoices;

      const {
        data: { results },
      } = await axiosWithNoAuth.get(
        `${APIURL}/public_site/map_services/${mapServiceId}/features/${this.gisDataPointId}/files`,
        {
          params: {
            folder: currentFolder,
            object_id: objectId,
            created_on: selectedMatchValue,
            created_on_match_choice: selectedMatchChoice,
            order_by: orderBy,
          },
        }
      );
      this.files = results.filter((r) => !r.created_from_pdf_file_id);
    },
  },
  async mounted() {
    this.loading = true;
    window.addEventListener("resize", this.onResize);
    await this.getFolders();
    await this.getFiles();
    this.loading = false;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    publicSite: {
      async handler() {
        this.loading = true;
        await this.getFolders();
        await this.getFiles();
        this.loading = false;
      },
      deep: true,
    },
    gisDataPointId: {
      immediate: true,
      async handler() {
        this.loading = true;
        await this.getFolders();
        await this.getFiles();
        this.loading = false;
      },
    },
  },
};
</script>

<style scoped>
#menu >>> ul {
  list-style-type: none;
  padding-left: 5px;
  cursor: pointer;
}

.bold {
  font-weight: 600;
}
</style>
