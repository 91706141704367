<template>
  <v-dialog :value="showPublicSiteNotFoundDialog" max-width="600px" persistent>
    <v-card>
      <v-card-text class="pt-4">
        The entered link either is no longer shared or does not exist.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PublicSiteNotFoundDialog",
  props: {
    showPublicSiteNotFoundDialog: Boolean,
  },
};
</script>
