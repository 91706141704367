var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { background: _vm.inRightPane } },
    [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center",
              class: { background: _vm.inRightPane },
              staticStyle: { overflow: "hidden" }
            },
            [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
            1
          )
        : [
            _vm.sharedSiteContacts.length > 0
              ? _c(
                  "v-list",
                  { staticClass: "overflow-y-auto" },
                  _vm._l(_vm.sharedSiteContacts, function(c) {
                    return _c(
                      "v-list-item",
                      { key: c.site_id + "-" + c.site_contact_id },
                      [
                        _c("v-list-item-content", [
                          _c("h3", [
                            _vm._v(_vm._s(c.f_name) + " " + _vm._s(c.l_name))
                          ]),
                          _c("section", [_vm._v(_vm._s(c.title))]),
                          _c("section", [_vm._v(_vm._s(c.company))]),
                          _c("section", [
                            _c("a", { attrs: { href: "mailto:" + c.email } }, [
                              _vm._v(_vm._s(c.email))
                            ])
                          ]),
                          _c("section", [
                            _c("a", { attrs: { href: "tel:" + c.phone_no } }, [
                              _vm._v(_vm._s(c.phone_no))
                            ])
                          ])
                        ])
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center" },
                  [_vm._v(" No Site Contacts Available ")]
                )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }