<template>
  <div :class="{ background: inRightPane }">
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="overflow: hidden"
      :class="{ background: inRightPane }"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <template v-else>
      <v-list class="overflow-y-auto" v-if="sharedSiteContacts.length > 0">
        <v-list-item
          v-for="c of sharedSiteContacts"
          :key="`${c.site_id}-${c.site_contact_id}`"
        >
          <v-list-item-content>
            <h3>{{ c.f_name }} {{ c.l_name }}</h3>
            <section>{{ c.title }}</section>
            <section>{{ c.company }}</section>
            <section>
              <a :href="`mailto:${c.email}`">{{ c.email }}</a>
            </section>
            <section>
              <a :href="`tel:${c.phone_no}`">{{ c.phone_no }}</a>
            </section>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-else class="d-flex justify-center align-center">
        No Site Contacts Available
      </div>
    </template>
  </div>
</template>

<script>
import { axiosWithJwtAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "PublicSiteSiteContacts",
  data() {
    return {
      sharedSiteContacts: [],
      loading: true,
    };
  },
  props: {
    publicSite: Object,
    inRightPane: Boolean,
  },
  methods: {
    async getSiteContacts() {
      this.loading = true;
      const { map_service_id: selectedMapServiceId, feature_id: objectId } =
        this.publicSite;
      if (!selectedMapServiceId || !objectId) {
        return;
      }

      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/public_site_external/shared_site_contacts`,
        {
          params: {
            map_service_id: selectedMapServiceId,
            feature_id: objectId,
            public: true,
          },
        }
      );
      this.sharedSiteContacts = results;
      this.loading = false;
    },
  },
  watch: {
    publicSite: {
      immediate: true,
      deep: true,
      handler() {
        this.getSiteContacts();
      },
    },
  },
};
</script>
