<template>
  <v-app-bar color="#3F51B5" class="white--text py-0 my-0" height="56px" app>
    <div class="d-flex justify-space-between align-center">
      <div class="white--text">
        <div :class="{ text: sharedSite.address }">{{ sharedSite.name }}</div>
        <div class="text">{{ sharedSite.address }}</div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { axiosWithNoAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "PublicSiteTopBar",
  props: {
    publicSite: Object,
  },
  data() {
    return {
      sharedSite: {},
    };
  },
  methods: {
    async getNameAndAddress() {
      const { map_service_id: mapServiceId, feature_id: objectId } =
        this.publicSite;

      if (!mapServiceId || !objectId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithNoAuth.get(
        `${APIURL}/public_site/shared_sites/${mapServiceId}/${objectId}`
      );
      this.sharedSite = results;
    },
  },
  beforeMount() {
    this.getNameAndAddress();
  },
  watch: {
    publicSite: {
      immediate: true,
      deep: true,
      handler() {
        this.getNameAndAddress();
      },
    },
  },
};
</script>

<style scoped>
.text {
  font-size: 12px;
}
</style>
