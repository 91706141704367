<template>
  <section>
    <v-expansion-panels
      flat
      multiple
      v-model="openPanels"
      class="py-3 mx-0 px-0"
    >
      <v-expansion-panel value="0">
        <v-expansion-panel-header class="text-uppercase nav-border-top">
          UtiliSync Fields
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-0">
          <section class="ma-0" v-if="gisDataFields.length > 0">
            <div
              v-for="f of gisDataFields"
              :key="f.gis_data_value_id"
              class="py-3 ma-0"
            >
              <div>
                <div class="caption">
                  {{ f.name }}
                </div>
                <div>
                  {{ f.value }}
                </div>
              </div>
            </div>
          </section>
          <section class="py-3" v-else>
            <div class="caption">No UtiliSync fields configured</div>
          </section>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel value="1">
        <v-expansion-panel-header
          class="text-uppercase nav-border-top my-0 py-0"
        >
          GIS Fields
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-if="publicSite.token_type === 'NONE'"
          class="my-0 py-0"
        >
          <section
            class="ma-0"
            v-if="Object.keys(gisInfoAttributes).length > 0"
          >
            <div
              v-for="(value, key) in gisInfoAttributes"
              :key="key"
              class="py-3"
            >
              <div class="caption">
                {{ key }}
              </div>
              <div>
                {{ value }}
              </div>
            </div>
          </section>
          <section class="ma-0" v-else>
            <div class="caption">No GIS fields configured</div>
          </section>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else>
          <div class="caption">Data Not Available</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
import { mdiGoogleMaps } from "@mdi/js";

export default {
  name: "PublicSiteFeatureGisInfo",
  props: {
    gisDataFields: Array,
    gisInfoAttributes: Object,
    publicSite: Object,
  },
  data() {
    return { mdiGoogleMaps, openPanels: [0, 1] };
  },
};
</script>

<style scoped>
.gap {
  gap: 5px;
}
</style>
