<template>
  <v-app style="background-color: #fafafa">
    <v-main fluid tag="section" class="pt-0">
      <section :class="{ 'overflow-hidden': $vuetify.breakpoint.lgAndUp }">
        <PublicSiteTopBar :publicSite="publicSite" />

        <v-row
          class="px-3 mt-16"
          v-if="!showPublicSiteNotFoundDialog"
          :class="{
            'my-0': $vuetify.breakpoint.mdAndDown,
            'py-0': $vuetify.breakpoint.mdAndDown,
          }"
          style="height: calc(100vh - 74px)"
        >
          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-column"
            :class="{
              'my-0': $vuetify.breakpoint.mdAndDown,
              'py-0': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card class="my-1 flex-grow-1">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('siteInformation')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiGoogleMaps }}
                    </v-icon>
                  </v-btn>
                  <div>Site Information</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.siteInformation
                          ? mdiChevronUp
                          : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.siteInformation">
                <PublicSiteGisInfo
                  v-if="publicSite.service_type === 'U'"
                  :gisDataFields="gisDataFields"
                />
                <template v-else>
                  <PublicSiteFeatureGisInfo
                    :gisDataFields="gisDataFields"
                    :gisInfoAttributes="gisInfoAttributes"
                    :publicSite="publicSite"
                  />
                </template>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-column"
            :class="{
              'my-0': $vuetify.breakpoint.mdAndDown,
              'py-0': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card class="my-1 flex-grow-1">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('siteContacts')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiMapMarkerAccountOutline }}
                    </v-icon>
                  </v-btn>
                  <div>Site Contacts</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.siteContacts ? mdiChevronUp : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>

              <v-card-text v-show="showSection.siteContacts">
                <v-divider class="my-3"></v-divider>
                <PublicSiteSiteContacts
                  v-if="publicSite"
                  :gisDataPointId="gisDataPointId"
                  :publicSite="publicSite"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-column"
            :class="{
              'my-0': $vuetify.breakpoint.mdAndDown,
              'py-0': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card class="my-1 flex-grow-1">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('documents')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiFile }}
                    </v-icon>
                  </v-btn>
                  <div>Documents</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.documents ? mdiChevronUp : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>

              <v-card-text v-show="showSection.documents">
                <PublicSiteDocsTab
                  v-if="publicSite"
                  :gisDataPointId="gisDataPointId"
                  :publicSite="publicSite"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <PublicSiteNotFoundDialog
        :showPublicSiteNotFoundDialog="showPublicSiteNotFoundDialog"
      />
    </v-main>
  </v-app>
</template>

<script>
import PublicSiteTopBar from "@/components/public-site/PublicSiteTopBar";
import PublicSiteGisInfo from "@/components/public-site/PublicSiteGisInfo";
import PublicSiteFeatureGisInfo from "@/components/public-site/PublicSiteFeatureGisInfo";
import PublicSiteDocsTab from "@/components/public-site/PublicSiteDocsTab";
import PublicSiteSiteContacts from "@/components/public-site/PublicSiteSiteContacts";
import PublicSiteNotFoundDialog from "@/components/public-site/PublicSiteNotFoundDialog";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import {
  mdiGoogleMaps,
  mdiFile,
  mdiMapMarkerAccountOutline,
  mdiChevronDown,
  mdiChevronUp,
} from "@mdi/js";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "PublicSite",
  components: {
    PublicSiteTopBar,
    PublicSiteGisInfo,
    PublicSiteDocsTab,
    PublicSiteNotFoundDialog,
    PublicSiteFeatureGisInfo,
    PublicSiteSiteContacts,
  },
  data() {
    return {
      refField: "",
      gisDataFields: [],
      publicSite: {},
      gisDataPointId: "",
      showPublicSiteNotFoundDialog: false,
      featureItemAttributes: {},
      featureItemFields: [],
      mdiGoogleMaps,
      mdiFile,
      mdiMapMarkerAccountOutline,
      mdiChevronDown,
      mdiChevronUp,
      showSection: {
        siteInformation: true,
        siteContacts: true,
        documents: true,
      },
    };
  },
  computed: {
    gisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (
          !this.featureItemAttributes ||
          !Array.isArray(this.featureItemFields)
        ) {
          return {};
        }

        const entries = this.featureItemFields.map(({ name: key }) => {
          const value = this.featureItemAttributes[key];

          return [this.findAliasByName(this.featureItemFields, key), value];
        });
        return Object.fromEntries(entries);
      } else {
        return {};
      }
    },
    notUtiliSyncLayer() {
      return this.publicSite?.service_type !== "U";
    },
  },
  methods: {
    onHeadingClick(sectionName) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$set(
          this.showSection,
          sectionName,
          !this.showSection[sectionName]
        );
      }
    },
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
    async getFeatureItemGisInfo() {
      const { feature_id: objectId, service_url: mapServiceUrl } =
        this.publicSite;
      try {
        const { data: queryResult } = await axiosWithNoAuth.get(
          `${mapServiceUrl}/query`,
          {
            params: {
              objectids: objectId,
              outFields: "*",
              f: "json",
            },
          }
        );
        const { features, fields } = queryResult;
        const [{ attributes }] = features;
        this.featureItemAttributes = attributes ?? {};
        const { globalIdFieldName } = queryResult;
        this.gisDataPointId = attributes?.[globalIdFieldName];
        this.featureItemFields = fields;
      } catch (error) {
        console.log(error);
      }
    },
    async getPublicSiteExternal() {
      try {
        const { urlId } = this.$route.params;
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/public_site_external/${urlId}`
        );
        const {
          ref_field: refField,
          gis_data_fields: gisDataFields,
          public_site: publicSite,
          gis_data_point_id: gisDataPointId,
        } = results;
        this.refField = refField;
        this.gisDataFields = gisDataFields;
        this.publicSite = publicSite;
        if (!this.notUtiliSyncLayer) {
          this.gisDataPointId = gisDataPointId;
        } else {
          const { global_id: globalId } = publicSite;
          this.gisDataPointId = globalId;
        }
      } catch (error) {
        this.showPublicSiteNotFoundDialog = true;
      }
    },
  },
  async mounted() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
    await this.getPublicSiteExternal();
    if (
      this.publicSite.token_type === "NONE" &&
      this.publicSite.service_type === "F"
    ) {
      await this.getFeatureItemGisInfo();
    }
  },
  beforeDestroy() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
};
</script>

<style scoped>
.disable-pointer {
  pointer-events: none;
}
</style>
