var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "white--text py-0 my-0",
      attrs: { color: "#3F51B5", height: "56px", app: "" }
    },
    [
      _c("div", { staticClass: "d-flex justify-space-between align-center" }, [
        _c("div", { staticClass: "white--text" }, [
          _c("div", { class: { text: _vm.sharedSite.address } }, [
            _vm._v(_vm._s(_vm.sharedSite.name))
          ]),
          _c("div", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.sharedSite.address))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }